import React from 'react'
import ImageGallery from 'react-image-gallery';
import '../styles/portfolio.scss'
import {Helmet} from "react-helmet";


function importAll(r) {
    return r.keys().map(r);
  }
   
const originals = importAll(require.context('../images/gallery/portfolio', false, /\.(png|jpe?g|svg)$/))
const thumbs = importAll(require.context('../images/gallery/portfolio/thumbs', false, /\.(png|jpe?g|svg)$/))
let images = []

for (let i = 0; i < originals.length; i++){
    images.push(
        {
            original : originals[i],
            thumbnal: thumbs[i]
        }
    )
}
//const images = [{original: uno, thumbnail: unot}, {original: dos, thumbnail: dost}]
console.log(images)
class MyGallery extends React.Component {
    render() {
      return (
        <div id="gallery">
        <Helmet>
            <title>Lago|Digital | Portfolio</title>
        </Helmet>
            <ImageGallery 
                items={images} 
                showThumbnails={false} 
                showFullscreenButton={false}
                showPlayButton={false}
            />
        </div>  
          );
    }
  }

export default MyGallery