import React from 'react'
import fotoPortada from '../images/portada.jpg'

class Home extends React.Component {

    render() {
        return( 
            <div id="content">
                <img src={fotoPortada} alt="La Habana" className="img-fluid" />
            </div>
             )
    }
}

export default Home