import {Helmet} from "react-helmet";
import React, {Component} from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import Home from "../pages/home"
import QuienesSomos from "../pages/quienes_somos"
import logo from '../images/lagodigital.png'
import Portfolio from '../pages/portfolio'
import Footer from '../components/footer'

class Layout extends Component {
    render() {
        return (
            
            <Router>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Lago|Digital | Antonio Lago Bornstein</title>
                <link rel="canonical" href="http://lagodigital.net" />
            </Helmet>
                    <header>
                    <Link to={'/'}>
                        <img src={logo} alt="LagoDigital" />
                        <h1 className="sr-only">LagoDigital | Antonio Lago Bornstein</h1>
                    </Link>
                
                    <nav className="navbar navbar-expand-lg navbar-dark">
                        <ul className="navbar-nav mr-auto">
                            <li><Link to={'/'} className="nav-link"> Home </Link></li>
                            <li><Link to={'/portfolio'} className="nav-link">Portfolio</Link></li>
                            <li><Link to={'/quienes-somos'} className="nav-link">Quienes Somos</Link></li>
                        </ul>
                    </nav>
                </header> 

                <Switch>
                    <Route exact path='/' component={Home} />
                    <Route path='/portfolio' component={Portfolio} />
                    <Route path='/quienes-somos' component={QuienesSomos} />
                </Switch>
                    
                <Footer />
            </Router>
        )
    }
}

export default Layout;
