import React from 'react'
import '../styles/quienes_somos.scss'
import {Helmet} from "react-helmet";

class QuienesSomos extends React.Component {
    render() {
        return(
            <div id="content" className="quienes-somos">
            <Helmet>
                <title>Lago|Digital | Quiénes Somos</title>
            </Helmet>
                <p>Lagodigital fue fundada en 1999 por Antonio Lago,  fotógrafo con más de treinta años de experiencia en este campo, y cuenta con una red de colaboradores que cubren la totalidad del territorio español.</p>
                <p>Lagodigital nace para dar servicios integrales de fotografía a empresas e instituciones y cuenta entre sus clientes con algunas de las mayores compañías españolas y globales, presentes en el IBEX-35, tales como Telefónica, Grupo Santander, Iberdrola, Indra, FCC y  Sacyr,  así como Cámara de Comercio e Industria de Madrid, APD, Foro de Nueva Economía, Real Instituto El Cano, Fundación Del Pino, entre otras.</p>
                <p>También ofrece sus servicios a empresas de Comunicación, tales como Dédalo, Inforpress, Torres y Carrera, Abascal Comunicación o Silvia Albert Comunicacion.</p>
                <p>Su fundador, Antonio Lago, es Diplomado en Fotografía por la Escuela de Artes y Oficios de Madrid, estudiando como alumno de la reputada fotógrafa Cristina García Rodero. Tras concluir sus estudios, compatibilizó la fotografía, principalmente de obra gráfica y arquitectura,  con la docencia en los colegios Rosales y Estudio.</p>
                <p>Más tarde, trabajo durante 10 años en Prensa, llegando a ser director de fotografía de un diario de difusión nacional.</p>
            </div>         
        )
    }
}

export default QuienesSomos