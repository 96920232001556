import React from 'react'

var today = new Date()
var year = today.getFullYear()
    
const Footer = () => {

    
    return(
        <footer>
            <p>© {year} Antonio Lago Bornstein</p>
        </footer>
    )
}

export default Footer